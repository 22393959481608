import { createContext } from 'react'
import { ResultsLocationData } from '../types'

export type TSearchLocationContext = {
  location: ResultsLocationData | null
}

export const SearchLocationContext = createContext<TSearchLocationContext>({
  location: null,
})
