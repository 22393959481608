import { createContext } from 'react'
import { Article } from '../../types'

export type TArticlesCategory = {
  id: number | null
  name: string
  articles: TArticlesData | null
}

export type TArticlesData = {
  data: Article[] | null
  total: number | null
}

export type TArticlesContext = {
  articles: TArticlesData | null
  categories?: TArticlesCategory[] | null
}

export const ArticlesContext = createContext<TArticlesContext>({
  articles: {
    data: null,
    total: null,
  },
  categories: null,
})
