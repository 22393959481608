import { gql } from '@apollo/client'

export const CITIES_IN_STATES = gql`
  query CitiesInStates($input: GeolocationInput) {
    CitiesInStates(input: $input) {
      name
      state_code
    }
  }
`
