import React from 'react'
import { format } from 'date-fns'

import classes from './Date.module.scss'

type TDateComponent = {
  end_date?: Date
  start_date?: Date
}

export default function DateComponent({ start_date, end_date }: TDateComponent) {
  const startDate = start_date ? new Date(`${start_date}`?.replace('Z', '')) : null
  const endDate = end_date ? new Date(`${end_date}`?.replace('Z', '')) : null

  // const dayStart = startDate && format(startDate, 'dd')
  // const dayEnd = endDate && format(endDate, 'dd')

  const dates = {
    start: startDate
      ? {
          day: format(startDate, 'dd'),
          month: format(startDate, 'MMM'),
          time: format(startDate, 'H:mm aa'),
        }
      : null,
    end: endDate
      ? {
          day: format(endDate, 'dd'),
          month: format(endDate, 'MMM'),
        }
      : null,
  }

  let sameMonth = dates.start?.month === dates.end?.month
  let sameDay = sameMonth && dates.start?.day === dates.end?.day
  const startTime = dates.start?.time

  return (
    <div className={classes.root}>
      {dates.start && <span>{dates.start.month}</span>}
      {dates.start && <span>{dates.start.day}</span>}
      {!sameDay && <span>-</span>}
      {!sameMonth && dates.end && <span>{dates.end.month}</span>}
      {!sameDay && dates.end && <span>{dates.end.day}</span>}
      {startTime && <span>•</span>}
      {startTime && <span>{startTime}</span>}
    </div>
  )
}
