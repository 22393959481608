import React from 'react'
import Image from 'next/image'

import classes from './Logo.module.scss'

type TLogo = { src: string; alt: string }

export default function Logo({ src, alt }: TLogo) {
  return (
    <div className={classes.root}>
      <Image src={src} alt={alt} width={36} height={36} />
    </div>
  )
}
