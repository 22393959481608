import React from 'react'

import Icon from '@/assets/icons/buttons/info'
import ToolTip from '@/components/ui/ToolTip/ToolTip'

type TInfoTooltip = {
  title: string
  iconColor?: string
  onOpen: () => void
  onClose: () => void
  open: boolean
}

// eslint-disable-next-line react/display-name
const IconRef = React.forwardRef((props: any, ref) => (
  // @ts-ignore
  <div {...props} ref={ref}>
    <Icon color={props.iconcolor} />
  </div>
))

export default function InfoTooltip({ onOpen, iconColor, ...props }: TInfoTooltip) {
  return (
    <ToolTip
      // PopperProps={{
      // disablePortal: true,
      // }}
      // disableFocusListener
      // disableHoverListener
      // disableTouchListener
      {...props}
    >
      <IconRef
        // @ts-ignore
        iconcolor={iconColor}
        onClick={onOpen}
      />
    </ToolTip>
  )
}
