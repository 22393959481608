import React, { ElementType } from 'react'

import classes from './Info.module.scss'

type TInfo = { children: React.ReactNode; Icon?: ElementType; wide?: boolean }

export default function Info({ children, Icon, wide }: TInfo) {
  let rootClasses = classes.root

  if (wide) {
    rootClasses += ` ${classes.root_wide}`
  }

  return (
    <div className={rootClasses}>
      {Icon && <Icon className={classes.icon} />}
      <div className={'nowrap-adaptive-container'} style={{ flex: 1 }}>
        <p className={`${classes.text} nowrap-text `}>{children}</p>
      </div>
    </div>
  )
}
