import React, { ReactNode } from 'react'
import Link from 'next/link'

import hypenString from '@helpers/formatters/hyphenLowercaseString'

import pluralize from 'pluralize'

import { ResultsLocationData } from '../../../../types'
import LocationSelector from '@/components/LocationSelector/LocationSelector'

import classes from './Header.module.scss'
import Title from '@/components/Results/components/Title/Title'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

type THeader = {
  title: string
  total: number
  location?: {
    value: ResultsLocationData | null
    selector?: boolean
  }
  onShowMapClick?: () => void
  children?: ReactNode
}

export default function Header({ title, total, location, onShowMapClick, children }: THeader) {
  let rootClasses = classes.root

  if (location?.value || onShowMapClick) {
    rootClasses += ` ${classes.root_column}`
  }

  const city =
    location?.value?.name && location?.value?.state_code
      ? {
          name: `${location?.value?.name}, ${location?.value?.state_code}`,
          href: `/${hypenString(location?.value?.state_code)}/${hypenString(location?.value?.name)}`,
        }
      : null

  let titleText = title

  if (location?.selector && location?.value) {
    titleText += ` ${location?.value?.name}`
  }

  const handleLocationLinkClick = () => {
    trackGAEvent({
      action: 'click',
      category: 'Results Section Header',
      label: `Location`,
      data: `page_url: ${window.location.href}`,
    })
  }

  return (
    <div className={rootClasses}>
      <div className={`${classes.section} ${classes.section_first}`}>
        <div className={classes.title}>
          <Title text={titleText} />
        </div>
        {location?.selector && location?.value && <LocationSelector location={location?.value} />}

        {!location?.selector && city && (
          <Link href={city.href} className={classes.city} onClick={handleLocationLinkClick}>
            {city.name}
          </Link>
        )}
      </div>
      {children && <div className={`${classes.section} ${classes.section_second}`}>{children}</div>}
      <div className={`${classes.section} ${classes.section_third}`}>
        <span
          className={`${classes.total} ${classes.text}`}
        >{`${pluralize('result', total, true)}`}</span>
        {onShowMapClick && (
          <span onClick={onShowMapClick} className={`link ${classes.text} ${classes.text_link}`}>
            Map view
          </span>
        )}
      </div>
    </div>
  )
}
