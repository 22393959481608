import ActivitiesIcon from '@/assets/icons/search/activities.svg'
import ArticlesIcon from '@/assets/icons/search/articles.svg'
import EventsIcon from '@/assets/icons/search/events.svg'
import LocationsIcon from '@/assets/icons/search/locations.svg'
import NotFoundIcon from '@/assets/icons/search/panda.svg'

export const GROUP_TYPE = {
  BUSINESS: 'business',
  CITY: 'city',
  CATEGORY: 'category',
  TAG: 'tag',
  EXPERIENCE: 'experience',
  SEARCH: 'search',
  ARTICLE: 'article',
  STATE: 'state',
}

export const GROUPS = {
  business: {
    name: 'Activities',
    title: 'Found activities',
    icon: <ActivitiesIcon />,
    allResults: true,
  },
  article: {
    name: 'Articles',
    title: 'Found articles',
    icon: <ArticlesIcon />,
    allResults: true,
  },
  events: {
    name: 'Events',
    title: 'Found events',
    icon: <EventsIcon />,
  },
  city: {
    name: 'Cities',
    title: 'Found cities',
    icon: <LocationsIcon />,
    allResults: true,
  },
  state: {
    name: 'States',
    title: 'Found states',
    icon: <LocationsIcon />,
    allResults: true,
  },
  noResults: {
    title: 'Ooops, We did not find any results.',
    name: 'Not Found',
    icon: <NotFoundIcon />,
  },
  more: {
    title: 'You may be interested in',
    name: 'More',
  },
}
