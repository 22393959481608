import React from 'react'

import classes from './Heading.module.scss'
import Link from 'next/link'

type THeading = {
  title: string
  description?: string
  promoted?: boolean
  wide?: boolean
  adittionalTitleClass?: string
  adittionalTitleStyles?: React.CSSProperties
  href: string
  id: number
}

export default function Heading({
  title,
  description,
  promoted,
  adittionalTitleClass,
  adittionalTitleStyles,
  href,
  id,
  wide,
}: THeading) {
  let wrapperClasses = classes.wrapper

  if (promoted && !wide) {
    wrapperClasses += ` ${classes.wrapper_column}`
  }

  return (
    <div className={classes.root}>
      <div className={wrapperClasses}>
        {promoted && (
          <div className={classes.promoted}>
            <Link href={href || ''} className={classes.action} id={`card-view-and-book-link-${id}`}>
              View & Book Classes
            </Link>
            <span className={classes.badge}>AD</span>
          </div>
        )}
        <div className={'nowrap-adaptive-container'}>
          <Link
            href={href || ''}
            // passHref
            prefetch={false}
            className={classes.link}
            draggable="false"
            target="_blank"
          >
            <b
              className={`nowrap-text ${classes.title} ${adittionalTitleClass || ''}`}
              style={adittionalTitleStyles ? { ...adittionalTitleStyles } : {}}
              id={`card-title-link-${id}`}
            >
              {title}
            </b>
          </Link>
        </div>
      </div>
      {description !== undefined && (
        <div className={'nowrap-adaptive-container'}>
          <p className={`nowrap-text ${classes.description}`}>{description}</p>
        </div>
      )}
    </div>
  )
}
