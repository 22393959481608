import { createContext } from 'react'
import { ResultsLocationData } from '../types'

export type TActivity = any

export type TActivitiesContext = {
  activities: {
    promoted: TActivity | null
    data: TActivity[] | null
    total: number | null
    location: ResultsLocationData | null
  } | null
}

export const ActivitiesContext = createContext<TActivitiesContext>({
  activities: {
    promoted: null,
    data: null,
    total: null,
    location: null,
  },
})
