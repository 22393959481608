import React from 'react'

import { Tag } from '../../types'

import classes from './Tags.module.scss'

export default function Tags({ tags, style }: { tags: Tag[]; style?: 'small' }) {
  let tagClass = classes.tag

  if (style === 'small') {
    tagClass += ` ${classes.tag_small}`
  }

  return (
    <div className={classes.root}>
      {tags.map((tag) => (
        <span className={tagClass} key={tag.id}>
          {tag.name}
        </span>
      ))}
    </div>
  )
}
