import { useMutation } from '@apollo/client'
import { TRACK_EVENT_MUTATION } from './mutations'

type TBeAKidEvent = {
  event_type: string
  event_name: string
  event_label: string
  event_category: string
  page_url?: string
  business_id?: number
  user_id?: number
  contact_me_request_id?: number
  ip_address?: string
  // ⚠️⚠️⚠️ analytics_type_id - Field from "analytics_type" table, use to set analytics source properly ⚠️⚠️
  analytics_type_id: number
}

const useTrackBeAKidEvent = () => {
  const [trackEvent, { loading, error }] = useMutation(TRACK_EVENT_MUTATION)

  const sendEvent = (props: TBeAKidEvent) => {
    console.log(
      JSON.stringify({
        variables: {
          input: {
            ...props,
          },
        },
      })
    )
    trackEvent({
      variables: {
        input: {
          ...props,
        },
      },
    })
  }

  return {
    sendEvent,
    loading,
    error,
  }
}

export default useTrackBeAKidEvent
