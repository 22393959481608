'use client'

import React, { useEffect, useState } from 'react'

import hypenString from '@helpers/formatters/hyphenLowercaseString'

import { useRouter } from 'next/navigation'
import { useLazyQuery } from '@apollo/client'
import AutocompleteComponent from '@/components/ui/Autocomplete/Autocomplete'
import { CITIES_IN_STATES } from '@/components/LocationSelector/queries'
import { ResultsLocationData } from '../../types'

import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

type TLocationSelector = {
  location: ResultsLocationData
}

export default function LocationSelector({ location }: TLocationSelector) {
  const router = useRouter()
  // const [value, setValue] = useState<any>(location)
  const [options, setOptions] = useState<any[]>([])

  const [fetchCities, { data }] = useLazyQuery(CITIES_IN_STATES, {
    variables: {
      input: {
        filters: {
          state_code: location?.state_code,
        },
      },
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    fetchCities()
  }, [fetchCities])

  useEffect(() => {
    if (data && data.CitiesInStates) {
      setOptions(data.CitiesInStates)
    }
  }, [data])

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: any) => {
    trackGAEvent({
      action: 'click',
      category: 'Results Section Header',
      label: `Location Autocomplete`,
      data: `page_url: ${window.location.href}, value: ${newValue}`,
    })
    if (newValue) {
      const newStateCode = hypenString(newValue.state_code)
      const newCityName = hypenString(newValue.name)
      router.push(`/${newStateCode}/${newCityName}`)
    }
  }

  return (
    <AutocompleteComponent
      options={options}
      id={'activities-location-selector'}
      value={location}
      onChange={handleChange}
      getOptionLabel={(option: any) => `${option.name}, ${option.state_code}`}
      renderOption={(props, option) => (
        <li {...props} key={`${option.name}-${option.state_code}`}>
          {option.name}, {option.state_code}
        </li>
      )}
    />
  )
}
