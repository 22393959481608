import React from 'react'

import { Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

type TDropdown = { children: any; style: any }

const CustomPaper = styled(Paper)(() => ({
  padding: '0',
  borderRadius: '20px',
  '& .MuiAutocomplete-listbox': {
    maxHeight: '60vh',
    '::-webkit-scrollbar': {
      // width: '27px',
      width: '23px',
    },
    '::-webkit-scrollbar-track': {
      background: '#ECEEEF',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '16px',
      background: '#1379FF',
      border: '2px solid transparent',
      backgroundClip: 'content-box',
    },
    // '::-webkit-scrollbar-thumb:hover': {
    //   background: '#555',
    // },
  },
}))

export default function Dropdown({ children }: TDropdown) {
  return <CustomPaper>{children}</CustomPaper>
  // return <CustomPaper {...props} />
  // return <CustomPaper {...props} style={{ width: props.anchorEl.clientWidth }} />
}
