import { useState, useEffect } from 'react'

export const useElementPosition = (ref) => {
  const [isOnTop, setIsOnTop] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return

      const elementTop = ref.current.getBoundingClientRect().top
      // console.log(elementTop)
      setIsOnTop(elementTop <= 1)
    }

    const throttledScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledScroll)

    return () => {
      window.removeEventListener('scroll', throttledScroll)
    }
  }, [ref])

  return { isOnTop }
}

function throttle(func, delay) {
  let lastCall = 0
  return function (...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return func(...args)
  }
}
