import { Article } from 'types'

const getTags = (article: Article) => {
  const tags: any[] = []

  if (article.experience) {
    tags.push({ name: article.experience?.name, id: 0 })
  }

  if (article.agegroup) {
    tags.push({ name: article.agegroup?.name, id: 1 })
  }

  if (article.city) {
    tags.push({ name: `${article.city.name}, ${article.city.state}`, id: 2 })
  }

  return tags
}

export default getTags
