import React from 'react'
import pluralize from 'pluralize'

import Tags from '@components/ResultNew/components/Tags/Tags'
import Heading from '@/components/Results/components/Cards/components/Heading/Heading'
import Info from '@/components/Results/components/Cards/components/Info/Info'
import ImageComponent from '@/components/Results/components/Cards/components/Image/Image'
import GridData from '@/components/Results/components/Cards/components/GridData/GridData'

import IconStarDisabled from '@/assets/icons/card/star-16x16.svg'
import IconGeoDisabled from '@/assets/icons/card/geo-point-16x16.svg'

import IconGeoActive from '@/assets/icons/card/geo-point-16x16-active.svg'
import IconStarActive from '@/assets/icons/card/star-16x16-active.svg'
import IconWeb from '@/assets/icons/card/web-16x16-active.svg'
import IconDollar from '@/assets/icons/card/dollar-16x16-active.svg'
import IconPhone from '@/assets/icons/card/phone-16x16-active.svg'

import Logo from '@/components/Results/components/Cards/components/Logo/Logo'

import classes from './Activity.module.scss'
import useTrackBeAKidEvent from '@helpers/trackEvent/trackBeAKidEvent'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'
// import extractHostname from '@/utils/extractHostname'

type TActivity = { activity: any; wide?: boolean }

const PRICES = {
  1: '$',
  2: '$$',
  3: '$$$',
  4: '$$$$',
}

export default function Activity({ activity, wide }: TActivity) {
  const { sendEvent } = useTrackBeAKidEvent()

  const {
    title,
    description,
    photos,
    experiences,
    location,
    rating,
    logo,
    promoted,
    phone,
    website,
    price,
    alias,
    id,
  } = activity

  const address = `${activity.location?.city}, ${activity.location?.state} ${activity.location?.postal_code}`
  const reviews = `(${pluralize('review', activity.reviews_count, true)})`

  let rootClasses = classes.root
  // let footerGridClasses = classes.grid

  let IconGeo = IconGeoDisabled
  let IconStar = IconStarDisabled

  if (promoted) {
    // footerGridClasses += ` ${classes.grid_columns}`
    if (wide) {
      rootClasses += ` ${classes.root_active} ${classes.root_wide}`
    }

    IconGeo = IconGeoActive
    IconStar = IconStarActive
  }

  const handleActivityClick = (e) => {
    // common with other cards
    if (e.target.id.includes('card-title-link')) {
      return
    }

    // common with other cards
    if (e.target.id.includes('card-view-and-book-link')) {
      return
    }

    // common with other cards
    if (e.target.id.includes('result-new-tags-slider-link')) {
      return
    }

    window.open(`/${alias}`, '_blank')

    let label = 'Activity'

    if (promoted) {
      sendEvent({
        event_type: 'click',
        event_name: 'promoted_business_card_click',
        event_label: 'Promoted Business Card Click',
        event_category: 'Results View',
        business_id: id,
        page_url: window.location.href,
        analytics_type_id: 2,
      })

      label = 'Activity Promoted'
    }

    trackGAEvent({
      action: 'click',
      category: 'Result Card',
      label,
      business_id: `${id}`,
      data: `alias: ${alias}, page_url: ${window.location.href}`,
    })
  }

  return (
    <div className={rootClasses} onClick={(e) => handleActivityClick(e)}>
      <ImageComponent images={photos} promoted={promoted} wide={wide} />
      <div className={classes.main}>
        <Heading
          title={title}
          description={description || ''}
          promoted={promoted}
          href={`/${alias}`}
          id={id}
          wide={wide}
        />
        <div className={classes.tags}>
          {experiences && location && (
            <div className={classes.wrapper}>
              <Tags
                items={experiences}
                type="small"
                location={{
                  ...location,
                  id: `${location.city}-${location.state}-${location.postal_code}`,
                }}
              />
            </div>
          )}
        </div>
        <div className={classes.footer}>
          <GridData wide={wide}>
            <Info Icon={IconGeo}>{address}</Info>
            {promoted && phone && <Info Icon={IconPhone}>{phone}</Info>}
            {promoted && price && <Info Icon={IconDollar}>{PRICES[price]}</Info>}
            <Info Icon={IconStar}>
              <b style={{ fontWeight: 600 }}>{rating}</b> {reviews}
            </Info>
            {promoted && website && (
              <Info Icon={IconWeb} wide={true}>
                {/*{extractHostname(website)} todo forcing hydration error*/}
                {website}
              </Info>
            )}
          </GridData>
          {logo && <Logo src={logo} alt={`${title} company logo`} />}
        </div>
      </div>
    </div>
  )
}
