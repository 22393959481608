import React from 'react'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

interface StyledInputProps {
  value?: string
}

export const StyledInput = styled(TextField)<StyledInputProps>(({ value, fullWidth }) => {
  const borderColor =
    value && value.length > 0 ? 'var(--color-border--secondary)' : 'var(--color-background)'

  return {
    '& .MuiTextField-root': fullWidth
      ? {
          width: '100%',
          flex: 1,
        }
      : {},
    '& .MuiInputBase-root': {
      height: '48px',
      borderRadius: '30px',
      backgroundColor: 'var(--color-background)',
      border: `1px solid ${borderColor}`,
      '&:hover': {
        borderColor: 'var(--color-border)',
      },
      '&:focus-within': {
        borderColor: 'var(--color-border)',
      },
      '&.Mui-error': {
        borderColor: 'var(--color-error)',
      },
    },
    '& .MuiInputBase-input': {
      padding: '0 20px',
      boxSizing: 'border-box',
      color: 'var(--color-text-main)',
      '&::placeholder': {
        color: 'var(--color-text-description-main)',
        opacity: 1,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInputLabel-root': {
      display: 'none',
    },
    '& .MuiFormHelperText-root': {
      '&.Mui-error': {
        color: 'var(--color-error)',
      },
    },
  }
})

type TInput = {
  id: string
  placeholder: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  helperText?: string
  fullWidth?: boolean
}

export default function Input({
  id,
  placeholder,
  value,
  onChange,
  error,
  helperText,
  fullWidth = true,
}: TInput) {
  return (
    <StyledInput
      id={id}
      placeholder={placeholder}
      variant="outlined"
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
    />
  )
}
