'use client'

import React, { useState, useEffect, useContext } from 'react'

import { SearchLocationContext } from '@/context/SearchLocationContext'

import { useLazyQuery } from '@apollo/client'
import { SEARCH_AUTOCOMPLETE_QUERY } from './queries'

import { Autocomplete } from '@mui/material'
import { StyledInput } from '@/components/ui/Input/Input'
import { styled } from '@mui/material/styles'

import EndAdornment from './components/EndAdornment/EndAdornment'
import Dropdown from './components/Dropdown/Dropdown'
import Groups from './components/Dropdown/components/Groups/Groups'
import Option from './components/Dropdown/components/Option/Option'

import useDebounce from '@hooks/useDebounce'
import hypenString from '@helpers/formatters/hyphenLowercaseString'

import { GROUP_TYPE } from '@/components/Search/constants'

import classes from './Search.module.scss'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

const CustomAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiInputBase-root': {
    height: 'var(--input-height) !important',
    padding: 'var(--input-search-paddings)',
  },
  '& .MuiInputBase-input': {
    '&:focus::placeholder': {
      color: 'var(--color-disabled)',
    },
  },
  '& .MuiAutocomplete-input': {
    fontSize: 'var(--input-search-font-size)',
    padding: 0,
  },
  '&.MuiAutocomplete-hasClearIcon': {
    '& .MuiInputBase-root': {
      height: 'var(--input-height)',
      padding: 'var(--input-search-paddings)',
    },
  },
}))

// test options
// const options = [
//   { name: 'Hill House', location: { city: 'Boston', state: 'MA' }, type: 'activities' },
//   {
//     name: 'Boys & Girls Clubs of Boston',
//     location: { city: 'Boston', state: 'MA' },
//     type: 'activities',
//   },
//   { name: 'Hill House', location: { city: 'Boston', state: 'MA' }, type: 'activities' },
//   {
//     name: 'Boys & Girls Clubs of Boston',
//     location: { city: 'Boston', state: 'MA' },
//     type: 'activities',
//   },
//   {
//     name: 'Merging Fun And Learning Entertaining Yet Educational Games For Children',
//     type: 'articles',
//   },
//   {
//     name: 'Dive Into Adventure Fun Filled Swimming Lakes With Kid Friendly Activities In Florida',
//     type: 'articles',
//   },
//   {
//     name: 'Merging Fun And Learning Entertaining Yet Educational Games For Children',
//     type: 'articles',
//   },
//   {
//     name: 'Dive Into Adventure Fun Filled Swimming Lakes With Kid Friendly Activities In Florida',
//     type: 'articles',
//   },
// ]

type OptionType = {
  name: string
  location?: { city: string; state: string }
  type: string
}

const noFoundOptions: OptionType[] = [
  { name: '', type: 'noResults' },
  { name: '', type: 'more' },
]

type TSearchProps = {
  collapsed?: boolean
  onFocus?: () => void
  onBlur?: () => void
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
}

const Search = ({ collapsed, onFocus, onBlur, isOpen, onOpen, onClose }: TSearchProps) => {
  const { location: locationContext } = useContext(SearchLocationContext)

  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<OptionType[]>([])
  const debouncedInput = useDebounce(inputValue, 500) // 500 ms delay

  const [getSearch, { data, loading, called }] = useLazyQuery(SEARCH_AUTOCOMPLETE_QUERY)

  const searchOption: OptionType | null = inputValue ? { name: inputValue, type: 'search' } : null

  const handleClear = () => setInputValue('')

  let placeholderText = 'Kids activities near'
  let locationText

  if (locationContext?.name && locationContext?.state_code) {
    locationText = `${locationContext?.name}, ${locationContext?.state_code}`
  } else if (locationContext?.state_code) {
    locationText = `${locationContext?.state_code}`
  } else {
    locationText = `me`
  }

  let placeholder = `${placeholderText} ${locationText}`

  let rootClasses = classes.root

  if (!collapsed) {
    rootClasses += ` ${classes.root_visible}`
  }

  const event_category = 'Search Bar'

  const handleSearchAction = () => {
    window.open(`/search?search=${inputValue}`, '_blank')
    // push(`/search?search=${inputValue}`)

    trackGAEvent({
      action: 'click',
      category: event_category,
      label: 'Submit',
      data: `page_url: ${window.location.href}`,
    })
  }

  const handleOpen = () => {
    if (inputValue.length > 0) {
      if (onOpen) {
        onOpen()
      }
    }
  }

  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue)
    if (newInputValue.length > 0) {
      if (onOpen) {
        onOpen()
      }
    } else {
      if (onClose) {
        onClose()
      }
    }
  }

  const handleOptionSelect = (option) => {
    const { type, location } = option
    let url = ''
    switch (type) {
      case GROUP_TYPE.BUSINESS:
        url = `/${option.alias}`

        trackGAEvent({
          action: 'click',
          category: event_category,
          label: 'Result Activity',
          data: `page_url: ${window.location.href}`,
        })

        break
      case GROUP_TYPE.ARTICLE:
        url = `/articles/${option.alias}`

        trackGAEvent({
          action: 'click',
          category: event_category,
          label: 'Result Article',
          data: `page_url: ${window.location.href}`,
        })

        break
      case GROUP_TYPE.CITY:
        if (location) {
          url = `/${hypenString(location.state?.toLowerCase())}/${hypenString(
            location.city?.toLowerCase(),
          )}`

          trackGAEvent({
            action: 'click',
            category: event_category,
            label: 'Result City',
            data: `page_url: ${window.location.href}`,
          })
        }
        break
      case GROUP_TYPE.STATE:
        if (location) {
          url = `/${hypenString(location.state?.toLowerCase())}`

          trackGAEvent({
            action: 'click',
            category: event_category,
            label: 'Result State',
            data: `page_url: ${window.location.href}`,
          })
        }
        break
      case GROUP_TYPE.SEARCH:
        handleSearchAction()

        trackGAEvent({
          action: 'click',
          category: event_category,
          label: 'Dropdown Submit',
          data: `page_url: ${window.location.href}`,
        })
        break
    }

    if (url) {
      window.open(url, '_blank')
    }
  }

  useEffect(() => {
    if (debouncedInput) {
      const location = {
        city: locationContext?.name,
        state: locationContext?.state_code || '',
        country: '',
        name: '',
        point: null,
      }

      ;(async () => {
        try {
          await getSearch({
            variables: {
              input: {
                search: debouncedInput,
                location,
                userIp: locationContext?.ip || null,
                limit: 30,
              },
            },
          })
          // setOptions([...noFoundOptions])

          trackGAEvent({
            action: 'event',
            category: event_category,
            label: 'Search Field Input',
            data: `page_url: ${window.location.href}`,
          })
        } catch (err) {
          console.log('Search: getSearch err', err)
        }
      })()
    }
  }, [debouncedInput])

  useEffect(() => {
    if (called && !loading && data?.searchAutocomplete) {
      if (data.searchAutocomplete.data && data.searchAutocomplete.data.length) {
        // console.log('data?.searchAutocomplete', data?.searchAutocomplete)
        setOptions([...data?.searchAutocomplete.data])
      }
    }
  }, [called, loading, JSON.stringify(data)])

  let optionsList: any = searchOption ? [searchOption, ...options] : [...options]

  if (options?.length === 0 && called && !loading) {
    optionsList = [...noFoundOptions]
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && inputValue) {
        handleSearchAction()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [inputValue])

  return (
    <div className={rootClasses}>
      <CustomAutocomplete
        freeSolo
        // open={true} //debugger
        open={isOpen}
        onOpen={handleOpen}
        onClose={onClose}
        inputValue={inputValue}
        filterOptions={(x) => x} // filtering feature disable
        options={optionsList}
        groupBy={(option: OptionType) => option.type}
        getOptionLabel={(option: OptionType) => option.name || inputValue}
        onInputChange={handleInputChange}
        onChange={(_, newValue: OptionType | string) => {
          // if (newValue && newValue.type === 'search') {
          if (newValue === inputValue) {
            handleSearchAction()
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        renderInput={(params) => (
          <StyledInput
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <EndAdornment
                  loading={loading}
                  onClear={inputValue ? handleClear : undefined}
                  onSubmit={handleSearchAction}
                />
              ),
            }}
          />
        )}
        // todo FixedHeader, Mobile view: search dropdown initial width is less then search input (seems like render before kids and user disapears)
        PaperComponent={Dropdown}
        // PaperComponent={({ children }) => <Dropdown>{children}</Dropdown>}
        renderOption={(_, option) => <Option option={option} onClick={handleOptionSelect} />}
        renderGroup={(params) => <Groups {...params} onShowAll={handleSearchAction} />}
        componentsProps={{
          popper: {
            sx: {
              boxShadow:
                '0 0 11px 0 rgba(204, 204, 204, 0.25), 0 7px 16px -2px rgba(0, 0, 0, 0.1), 0 20px 18px 0 rgba(0, 0, 0, 0.25)',
              borderRadius: '20px',
              overflow: 'hidden',
            },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 6],
                },
              },
            ],
          },
        }}
      />
    </div>
  )
}

export default Search
