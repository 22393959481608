import React, { useState, useEffect } from 'react'

import Slider from 'react-slick' // https://react-slick.neostack.com/docs/api
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import TagsItem from './TagsItem'

import classes from './Tags.module.scss'

import * as types from '../../../../types'
import { Location } from '../../../../types'

type TBusinessCardTagsProps = {
  items: types.Experience[] | types.Tag[]
  isLight?: boolean
  type?: 'small' | undefined
  location: Location
}

function Tags({ items, isLight, type, location }: TBusinessCardTagsProps) {
  const [isSwiping, setSwiping] = useState(false)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleAfterChange = () => {
    setSwiping(false)
  }

  const handleSwipe = () => {
    setSwiping(true)
  }

  if (!isClient) {
    return (
      <div className={`${classes.root} ${classes.root_nojs}`}>
        {items &&
          items.length > 0 &&
          items.map((item) => (
            <TagsItem
              key={item.id}
              item={item}
              location={location}
              isLight={isLight}
              isSwiping={false}
              type={type}
              noJs={!isClient}
            />
          ))}
      </div>
    )
  }

  return (
    <Slider
      arrows={false}
      dots={false}
      infinite={false}
      speed={300}
      className={`slider ${classes.root}`}
      variableWidth={true}
      swipeToSlide={true}
      draggable={items?.length > 2}
      afterChange={handleAfterChange}
      onSwipe={handleSwipe}
    >
      {items &&
        items.length > 0 &&
        items.map((item) => (
          <TagsItem
            key={item.id}
            item={item}
            location={location}
            isLight={isLight}
            isSwiping={isSwiping}
            type={type}
          />
        ))}
    </Slider>
  )
}

export default React.memo(Tags)
