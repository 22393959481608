import React, { ReactNode } from 'react'

import IconRight from '@/assets/icons/arrows/chevron-right-grey-14x14.svg'

import classes from './Button.module.scss'

type TButton = { name: string; icon: ReactNode; url: string }

export default function Button({ name, icon, url }: TButton) {
  const handleClick = () => {
    window.open(url, '_blank')
  }

  if (!url) {
    return null
  }
  return (
    <button type="button" onClick={handleClick} className={classes.root}>
      {icon}
      <div className={classes.name}>{name}</div>
      <IconRight />
    </button>
  )
}
