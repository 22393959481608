import React, { useContext } from 'react'
import Link from 'next/link'

import { AutocompleteRenderGroupParams } from '@mui/material'
import { GROUP_TYPE, GROUPS } from '@/components/Search/constants'

import Button from '@/components/Search/components/Dropdown/components/Button/Button'

import classes from './Groups.module.scss'
import hypenString from '@helpers/formatters/hyphenLowercaseString'
import { SearchLocationContext } from '@/context/SearchLocationContext'

type TGroups = { onShowAll: () => void } & AutocompleteRenderGroupParams

export default function Groups(params: TGroups) {
  const { location } = useContext(SearchLocationContext)

  const icon = GROUPS[params.group]?.icon
  const title = GROUPS[params.group]?.title
  const allResults = GROUPS[params.group]?.allResults

  let rootClasses = classes.root

  if (params.group === 'search') {
    rootClasses += ` ${classes.root_search}`
  }

  if (params.group === 'noResults') {
    rootClasses += ` ${classes.root_noResults}`
  }

  if (params.group === 'more') {
    rootClasses += ` ${classes.root_noResults}`
  }

  const handleShowAllResults = allResults
    ? (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (params.onShowAll) {
          params.onShowAll()
        }
      }
    : null

  const resultsPageUrl = (type) => {
    let url
    switch (type) {
      case GROUP_TYPE.BUSINESS:
        if (location) {
          url = `/${hypenString(location.state_code?.toLowerCase())}/${hypenString(
            location.name?.toLowerCase(),
          )}/activities`
        }
        break
      case GROUP_TYPE.ARTICLE:
        if (location) {
          url = `/${hypenString(location.state_code?.toLowerCase())}/${hypenString(
            location.name?.toLowerCase(),
          )}/articles`
        }
        break
      case GROUP_TYPE.CITY:
        if (location) {
          url = `/${hypenString(location.state_code?.toLowerCase())}/${hypenString(
            location.name?.toLowerCase(),
          )}`
        }
        break
      case GROUP_TYPE.STATE:
        url = `/states`
        break
    }

    return url
  }

  const Group = () => {
    if (params.group === 'noResults') {
      return (
        <>
          <b className={classes.text}>{title}</b>
          <div className={classes.icon}>{icon}</div>
        </>
      )
    }

    if (params.group === 'more') {
      return (
        <>
          <b className={classes.helper}>{title}</b>
          <div className={classes.buttons}>
            {Object.keys(GROUPS).map((type, i) => {
              const group = GROUPS[type]
              const url = resultsPageUrl(type)
              return <Button key={i} {...group} url={url} />
            })}
          </div>
        </>
      )
    }

    return (
      <>
        <div className={classes.heading}>
          <div className={classes.wrapper}>
            {icon}
            <b className={classes.title}>{title}</b>
          </div>
          {handleShowAllResults && (
            <Link href={'#'} onClick={handleShowAllResults} className={classes.link}>
              All results
            </Link>
          )}
        </div>
        <ul className={classes.list}>{params.children}</ul>
      </>
    )
  }

  return (
    <div key={params.key} className={rootClasses}>
      <Group />
    </div>
  )
}
