import { createContext } from 'react'
import { Experience } from '../../types'

export type TExperiencesContext = {
  experiences: Experience[] | null
}

export const ExperiencesContext = createContext<TExperiencesContext>({
  experiences: [],
})
