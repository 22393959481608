import React from 'react'
import Link from 'next/link'

import classes from './Atuhor.module.scss'
import Image from 'next/image'
import { format } from 'date-fns'

type TAtuhor = {
  author: any
  publishedAt: any
  id: string
}

export default function Atuhor({ author, publishedAt, id }: TAtuhor) {
  if (!author) {
    return null
  }

  return (
    <Link
      href={`/articles/authors/${author.alias}`}
      className={classes.root}
      id={id}
      onClick={(e) => e.stopPropagation()}
    >
      {author?.avatar && (
        <div className={classes.image}>
          <Image
            src={`${process.env.NEXT_PUBLIC_PHOTO_STORAGE}/${author?.avatar}`}
            quality={50}
            alt={author.name}
            width={46}
            height={46}
          />
        </div>
      )}
      <div className={classes.info}>
        {author?.fullName && <span>{author?.fullName}</span>}
        <span className={classes.date}>{format(new Date(publishedAt), 'MMM dd yyyy')}</span>
      </div>
    </Link>
  )
}
