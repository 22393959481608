import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import Button from '@/components/Search/components/Button/Button'

import IconClear from '@/assets/icons/search/clear.svg'
import IconSearch from '@/assets/icons/search/magnify.svg'

import classes from './EndAdornment.module.scss'

type TEndAdornment = {
  onClear?: () => void
  onSubmit: () => void
  loading?: boolean
}

export default function EndAdornment({ onClear, onSubmit, loading }: TEndAdornment) {
  if (loading) {
    return <CircularProgress color="inherit" size={20} />
  }

  return (
    <div className={classes.root}>
      {onClear && (
        <Button onClick={onClear}>
          <IconClear />
        </Button>
      )}
      {onClear && <div className={classes.separator} />}
      <Button onClick={onSubmit}>
        <IconSearch />
      </Button>
    </div>
  )
}
