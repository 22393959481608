import { useCallback, useState } from 'react'

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = useCallback(
    function toggle() {
      setIsOpen(!isOpen)
    },
    [isOpen]
  )

  return {
    isOpen,
    toggle,
  }
}

export default useModal
