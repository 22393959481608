import React from 'react'
import Link from 'next/link'

import hypenString from '@helpers/formatters/hyphenLowercaseString'

import classes from './Tags.module.scss'

import * as types from '../../../../types'
import { Location } from '../../../../types'

type TBusinessCardTagsProps = {
  item: types.Tag | types.Experience
  // onTagPress: (id: number) => void
  className?: any
  isActive?: boolean
  isLight?: boolean
  isSwiping?: boolean
  type?: 'small' | undefined
  location: Location
  noJs?: boolean
}

const TAGS_COLORS = ['yellow', 'purple', 'red', 'blue', 'green']

function TagsItem({
  item,
  location,
  // onTagPress,
  isLight,
  isSwiping,
  // className,
  isActive,
  type,
  noJs,
}: TBusinessCardTagsProps) {
  const tagIdNumber = Number(item.id)
  let tagColor
  let textStyles = isLight ? `${classes.item} ${classes.item_light}` : classes.item

  if (tagIdNumber % TAGS_COLORS.length === 0) {
    // multiple of 5.
    tagColor = 5
  } else {
    // not a multiple of 5.
    const integerDivision = Math.floor(tagIdNumber / TAGS_COLORS.length)
    tagColor = tagIdNumber - integerDivision * 5
  }

  let tagStyles = `${textStyles} ${classes[`item_${TAGS_COLORS[tagColor - 1]}`]}`

  if (isActive) {
    tagStyles += ` ${classes.item_active}`
  }

  if (type === 'small') {
    tagStyles += ` ${classes.item_small}`
  }

  if (noJs) {
    tagStyles += ` ${classes.item_nojs}`
  }

  const handleLinkClick = (e) => {
    if (isSwiping) {
      e.preventDefault()
      return
    }
  }

  const stateIdParam = hypenString(location.state.toLowerCase())
  const cityIdParam = hypenString(location.city.toLowerCase())
  const experienceIdParam = hypenString(item.name.toLowerCase())

  return (
    <Link
      href="/old/[stateId]/[cityId]/choice/[experienceId]"
      as={`/${stateIdParam}/${cityIdParam}/choice/${experienceIdParam}`}
      prefetch={false}
      className={tagStyles}
      draggable={false}
      onClick={handleLinkClick}
      id={`result-new-tags-slider-link-${item.name}-${location.id}`}
    >
      {item.name}
    </Link>
  )
}

export default TagsItem
