import React from 'react'

import { Event as EventType } from '../../../../../../types' // old types

import Heading from '@/components/Results/components/Cards/components/Heading/Heading'
import GridData from '@/components/Results/components/Cards/components/GridData/GridData'
import Info from '@/components/Results/components/Cards/components/Info/Info'
import ImageComponent from '@/components/Results/components/Cards/components/Image/Image'

import Date from './components/Date/Date'

import IconGeo from '@/assets/icons/card/geo-point-16x16.svg'
import IconArrow from '@/assets/icons/card/arrow-blue-right-top-15x16.svg'

import classes from './Event.module.scss'
import hypenString from '@helpers/formatters/hyphenLowercaseString'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

type TEvent = {
  event: EventType
  titleLines?: 1 | 2
}

export default function Event({ event, titleLines }: TEvent) {
  const { id, title, location, image, image_url, alias } = event

  const thumbnailUrl = image || image_url

  const thumbnailSrc = !thumbnailUrl?.includes('http')
    ? `${process.env.NEXT_PUBLIC_PHOTO_STORAGE}/${thumbnailUrl}`
    : `${thumbnailUrl}`

  const images = thumbnailUrl
    ? [
        {
          width: 358,
          height: 167,
          src: thumbnailSrc,
          alt: `${title} event image`,
        },
      ]
    : []

  const address =
    location?.city && location?.state
      ? `${location?.city}, ${location?.state} ${location?.postcode}`
      : null

  const url = `/${hypenString(location?.state)}/${hypenString(location?.city)}/events/${alias}`

  const handleEventClick = (e) => {
    // common with other cards
    if (e.target.id.includes('card-title-link')) {
      return
    }

    // common with other cards
    if (e.target.id.includes('card-view-and-book-link')) {
      return
    }

    // common with other cards
    if (e.target.id.includes('result-new-tags-slider-link')) {
      return
    }

    window.open(url, '_blank')

    trackGAEvent({
      action: 'click',
      category: 'Result Card',
      label: 'Event',
      data: `id: ${id}, page_url: ${window.location.href}`,
    })
  }

  return (
    <div className={classes.root} onClick={(e) => handleEventClick(e)}>
      <Date {...event} />
      <div className={classes.image}>
        <ImageComponent images={images} />
      </div>
      <div className={classes.heading}>
        <Heading
          title={title}
          adittionalTitleClass={titleLines === 1 ? 'nowrap-text' : 'nowrap-text--2-lines'}
          href={url}
          id={Number(id)}
        />
      </div>
      <div className={classes.footer}>
        <GridData>{address && <Info Icon={IconGeo}>{address}</Info>}</GridData>
        <button type="button" className={classes.button}>
          <IconArrow />
        </button>
      </div>
    </div>
  )
}
