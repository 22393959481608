import { createContext } from 'react'

import { ResultsLocationData } from '../types' // new types
import { Event } from '../../types' // old types

export type TEventsContext = {
  events: {
    data: Event[] | null
    total: number | null
    location: ResultsLocationData | null
  } | null
}

export const EventsContext = createContext<TEventsContext>({
  events: {
    data: null,
    total: null,
    location: null,
  },
})
