import React from 'react'

import classes from './Button.module.scss'

type TSubmit = {
  children: React.ReactNode
  onClick: () => void
}

export default function Button({ children, onClick }: TSubmit) {
  return (
    <button type="button" className={classes.root} onClick={onClick}>
      {children}
    </button>
  )
}
