import React from 'react'

import classes from './GridData.module.scss'

type TGridData = {
  children: React.ReactNode
  wide?: boolean
}

export default function GridData({ children, wide }: TGridData) {
  let rootClasses = classes.root

  if (wide) {
    rootClasses += ` ${classes.root_wide}`
  }

  return <div className={rootClasses}>{children}</div>
}
