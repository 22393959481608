import React from 'react'

import classes from './Option.module.scss'

import SearchIcon from '@/assets/icons/search/magnify.svg'

type TOption = { option: any; onClick: (option: any) => void }

export default function Option({ option, onClick }: TOption) {
  let rootClasses = classes.root

  if (option.type === 'search') {
    rootClasses += ` ${classes.root_search}`
    if (option.name) {
      return (
        <li className={rootClasses} onClick={() => onClick(option)}>
          <SearchIcon />
          <b className={classes.title}>{option.name}</b>
        </li>
      )
    } else {
      return null
    }
  }

  let location

  if (option.type !== 'city' || option.type !== 'state') {
    const city = option?.location?.city
    const state = option?.location?.state

    location = state || city

    if (city && state) {
      location = `${city}, ${state}`
    }
  }

  return (
    <li className={rootClasses} onClick={() => onClick(option)}>
      <b className={classes.title}>{option.name}</b>
      {location && <i className={classes.info}>{location}</i>}
    </li>
  )
}
