import React from 'react'

import classes from './List.module.scss'

type TList = {
  children: React.ReactNode
  columns?: number
}

export default function List({ children, columns }: TList) {
  let rootClasses = classes.root

  if (columns === 2) {
    rootClasses += ` ${classes.root_two_cols}`
  }

  if (columns === 3) {
    rootClasses += ` ${classes.root_three_cols}`
  }

  if (columns === 4) {
    rootClasses += ` ${classes.root_four_cols}`
  }

  if (columns === 5) {
    rootClasses += ` ${classes.root_five_cols}`
  }

  return <div className={rootClasses}>{children}</div>
}
