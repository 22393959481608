'use client'
import React, { useContext, useMemo } from 'react'
import { usePathname } from 'next/navigation'
import pluralize from 'pluralize'

import { ActivitiesContext } from '@/context/ActivitiesContext'
import { EventsContext } from '@/context/EventsContext'
import { ArticlesContext } from '@/context/ArticlesContext'
// season activities
// import { SeasonActivitiesContext } from '@/context/SeasonActivitiesContext'
import { ViewportContext } from '@/context/ViewportContext'
import { useAuth } from '@/context/AuthContext'

import IconTown from '@/assets/icons/nav-actions/town.svg'
import IconLocation from '@/assets/icons/nav-actions/location.svg'
import IconActivities from '@/assets/icons/nav-actions/activities.svg'
import IconArticles from '@/assets/icons/nav-actions/articles.svg'
import IconFamily from '@/assets/icons/nav-actions/family.svg'
// season activities
// import IconCamps from '@/assets/icons/nav-actions/camps.svg'

import NavItem, { TNavItem } from './components/NavItem/NavItem'

import ButtonsSlider from '@/components/Sliders/ButtonsSlider/ButtonsSlider'
import useViewport from '@/hooks/useViewport'
import useKids from '@/hooks/useKids'

import hypenString from '@helpers/formatters/hyphenLowercaseString'

type TNavActions = {}

export default function NavActions({}: TNavActions) {
  const pathname = usePathname()
  const { isMobile } = useContext(ViewportContext)
  const viewport = useViewport({ isMobile })

  const { user, setIsOpen } = useAuth()

  const { kids } = useKids(user?.id || null)
  const { activities } = useContext(ActivitiesContext)
  const { events } = useContext(EventsContext)
  const { articles } = useContext(ArticlesContext)
  // season activities (to check page ssr for results)
  // const { seasonActivities } = useContext(SeasonActivitiesContext)

  const townCounter = (activities?.total || 0) + (events?.total || 0) + (articles?.total || 0)
  const city = activities?.location?.name || ''
  const state = activities?.location?.state_code || ''

  // season activities
  // const formattedTags =
  //   seasonActivities?.filters?.tags && seasonActivities?.filters?.tags.length
  //     ? seasonActivities?.filters.tags.map((filter) => hypenString(filter))
  //     : null
  // const tagsFilterParam = formattedTags ? `?tags=${formattedTags.join(',')}` : ''

  const itemList: TNavItem[] = useMemo(
    () => [
      {
        name: 'My Town',
        description: `${pluralize('Item', townCounter, true)}`,
        Icon: IconTown,
        href: city && state ? `/${hypenString(state)}/${hypenString(city)}` : '',
      },
      {
        name: 'Local Events',
        description: `${pluralize('Event', events?.total || 0, true)}`,
        Icon: IconLocation,
        href: city && state ? `/${hypenString(state)}/${hypenString(city)}/events` : '',
      },
      {
        name: 'Local Activities',
        description: `${pluralize('Activity', activities?.total || 0, true)}`,
        Icon: IconActivities,
        href: city && state ? `/${hypenString(state)}/${hypenString(city)}/activities` : '',
      },
      {
        name: 'Articles',
        description: `${pluralize('Article', articles?.total || 0, true)}`,
        Icon: IconArticles,
        href: city && state ? `/${hypenString(state)}/${hypenString(city)}/articles` : '',
      },
      {
        name: 'My Family',
        description:
          kids && kids?.length ? `${pluralize('Kid', kids.length || 0, true)}` : 'Add kid',
        Icon: IconFamily,
        href: user ? `${process.env.NEXT_PUBLIC_GIS_USER_PORTAL}/kids` : '',
        onClick: !user ? () => setIsOpen(true) : undefined,
        target: '_blank',
      },
      // season activities
      // {
      //   name: 'Summer Camps',
      //   description: `${pluralize('Camps', seasonActivities?.total || 0, true)}`,
      //   Icon: IconCamps,
      //   href:
      //     city && state ? `/${hypenString(state)}/${hypenString(city)}/all${tagsFilterParam}` : '',
      // },
    ],
    [
      events,
      activities,
      articles,
      kids,
      user,
      setIsOpen,
      // season activities
      // , seasonActivities?.total
    ],
  )

  const Items = itemList.map((item, i) => {
    const isActive = pathname === item.href

    // eslint-disable-next-line react/display-name
    return () => <NavItem key={i} {...item} active={isActive} />
  })

  return <ButtonsSlider Items={Items} jsSlider={viewport !== 'desktop'} />
}
