import { createContext } from 'react'
import { ResultsFiltersData, ResultsLocationData } from '../types'

export type TSeasonActivity = any

export type TSeasonActivitiesContext = {
  seasonActivities: {
    promoted: TSeasonActivity | null
    data: TSeasonActivity[] | null
    total: number | null
    location: ResultsLocationData | null
    filters: ResultsFiltersData | null
  } | null
}

export const SeasonActivitiesContext = createContext<TSeasonActivitiesContext>({
  seasonActivities: {
    promoted: null,
    data: null,
    total: null,
    location: null,
    filters: null,
  },
})
