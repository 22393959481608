import React, { ElementType } from 'react'
import Link from 'next/link'

import classes from './NavItem.module.scss'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

export type TNavItem = {
  name: string
  description: string
  Icon: ElementType
  href: string
  onClick?: () => void
  disabled?: boolean
  active?: boolean
  target?: '_self' | '_blank'
}

export default function NavItem({
  name,
  Icon,
  description,
  href,
  onClick,
  disabled,
  active,
  target = '_self',
}: TNavItem) {
  let rootClasses = classes.root

  if (disabled) {
    rootClasses += ` ${classes.root_disabled}`
  }

  if (active) {
    rootClasses += ` ${classes.root_active}`
  }

  const event_category = 'Nav Actions Bar'

  const handleLinkClick = (e) => {
    if (onClick) {
      e.preventDefault()
      onClick()

      trackGAEvent({
        action: 'click',
        category: event_category,
        label: name,
        data: `page_url: ${window.location.href}`,
      })
    }
  }

  return (
    <Link href={href} target={target} className={rootClasses} onClick={handleLinkClick}>
      <div className={classes.icon}>
        <Icon />
      </div>
      <div className={classes.text}>
        <b className={classes.title}>{name}</b>
        <span className={classes.sub}>{description}</span>
      </div>
    </Link>
  )
}
