import React from 'react'

import ImageComponent from '@/components/Results/components/Cards/components/Image/Image'
import Heading from '@/components/Results/components/Cards/components/Heading/Heading'
import Tags from '@components/Tags/Tags'
import getTags from '@helpers/article/getTags'
import getReadingTime from '@helpers/estimateReadingTime/estimateReadingTime'
import Author from './components/Author/Atuhor'

import classes from './Article.module.scss'

import { Article as ArticleType } from '../../../../../../types'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

type TArticle = {
  data: ArticleType
}

export default function Article({ data }: TArticle) {
  const images =
    data?.photos && data?.photos.length > 0
      ? data.photos.map((image) => {
          return {
            src: image.uri,
            alt: image.alt || data.title,
          }
        })
      : []

  const tags = getTags(data)
  const titleId = `article-${data.id}-link-title`
  const authorId = `article-${data.id}-link-author`

  const url = `/articles/${data.alias}`

  const handleClick = (e) => {
    // common with other cards
    if (e.target.id.includes('card-title-link')) {
      return
    }

    // common with other cards
    if (e.target.id.includes('card-view-and-book-link')) {
      return
    }

    // common with other cards
    if (e.target.id.includes('result-new-tags-slider-link')) {
      return
    }

    if (e.target.id.includes(titleId)) {
      return
    }

    if (e.target.id.includes(authorId)) {
      return
    }

    window.open(url)

    trackGAEvent({
      action: 'click',
      category: 'Result Card',
      label: 'Article',
      data: `id: ${data.id}, page_url: ${window.location.href}`,
    })
  }

  return (
    <div onClick={handleClick} className={classes.root}>
      <div className={classes.image}>
        <ImageComponent images={images} />
      </div>
      <Heading
        title={data.title}
        adittionalTitleClass={'nowrap-text--2-lines'}
        adittionalTitleStyles={{ minHeight: '40px' }}
        href={url}
        id={data.id}
      />
      <div className={classes.tags}>{tags && <Tags tags={tags} />}</div>
      <div className={classes.footer}>
        <Author author={data.author} publishedAt={data.publishedAt} id={authorId} />
        <span className={classes.time}>{`${getReadingTime(data.content)} min read`}</span>
      </div>
    </div>
  )
}
