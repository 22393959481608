import { useState, useEffect, useCallback } from 'react'
import { Kid } from '../../types'

const fetchKids = async () => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_GIS_HOME}/api/user/kids/get`, {
      method: 'GET',
      credentials: 'include',
    })

    return await res.json()
  } catch (e) {
    console.log('[fetchKids] Something went wrong while getting kids...', e)
    return null
  }
}

const useKids = (userId: string | number | null) => {
  const [kids, setKids] = useState<Kid[] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchKidsData = useCallback(async () => {
    if (userId) {
      try {
        setLoading(true)
        const kidsData = await fetchKids()
        setKids(kidsData)
      } catch (e) {
        setError(e as Error)
        setKids(null)
      } finally {
        setLoading(false)
      }
    } else {
      setKids(null)
      setLoading(false)
    }
  }, [userId])

  useEffect(() => {
    fetchKidsData()
  }, [fetchKidsData])

  useEffect(() => {
    if (!userId) {
      setKids(null)
      setLoading(false)
      setError(null)
    }
  }, [userId])

  return { kids, loading, error, refetch: fetchKidsData }
}

export default useKids
