import { gql } from '@apollo/client'

export const SEARCH_AUTOCOMPLETE_QUERY = gql`
  query searchAutocomplete($input: SearchInput) {
    searchAutocomplete(input: $input) {
      search
      total
      data {
        id
        name
        location {
          id
          city
          state
        }
        alias
        type
      }
    }
  }
`
